import { useCallback } from 'react';
import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  GoogleAnalyticsClient,
} from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { getFBAnalyticsParams } from '@/controllers/analytics/analytics.utils/getFBAnalyticsParams';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';

export const useCollectSignUpData = () => {
  const { subDomain, language, productName } = useSubDomainContext();
  const [localStorageReferralCode] = useLocalStorage(LOCAL_STORAGE_KEYS.referralCode, '');

  return useCallback(() => {
    const userUtm = GoogleAnalyticsClient
      .getInstance(productName)
      .getUserSourceData();
    const fbAnalyticsParams = getFBAnalyticsParams();

    const referralCode = isBrowser
      ? localStorageReferralCode
      : '';

    return {
      ...userUtm,
      ...fbAnalyticsParams,
      referralCode,
      locale: language,
      domain: subDomain,
    };
  }, [language, subDomain, localStorageReferralCode, productName]);
};
